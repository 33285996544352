import React, { FC, useEffect, useRef, useState, useContext, useCallback } from 'react'
import { useSelector } from '@xstate/react'
import { GlobalStateContext } from './globalState'

import { Next, Prev, Loop, Play, Pending, PlayArrow, Stop, Pause, Delete, Download, ChevronRight, ChevronLeft, Fullscreen, Settings, Mic, Search, Upload, Edit, Record, WebLink, List } from './icons'

import { PlayerService } from './state/player.machine'
import { formatTime } from './faders'


type FileAddModalProps = {
  onAddFiles: (files: FileList) => void
  open: boolean
}
export const FileAddButton: FC<FileAddModalProps> = ({
  onAddFiles,
  open
}: FileAddModalProps) => {
  const fileInput = useRef<HTMLInputElement>(null)
  const onButtonClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (fileInput.current)
      fileInput.current.click()
  }
  const onFileChange = (e: React.ChangeEvent) => {
    e.preventDefault()
    e.stopPropagation()
    return (e.target as HTMLInputElement)?.files?.length
      // @ts-ignore
      ? onAddFiles((e.target as HTMLInputElement).files)
      : null
  }

  return (
    <>
      <button disabled={!open} className='btn btn-sm btn-circle' onClick={onButtonClick}>
        <Upload className='w-6 inline' />
      </button >
      <input type="file"
        accept='audio/*,video/*'
        onChange={onFileChange}
        className='hidden'
        ref={fileInput}
        multiple
      />
    </>
  )
}

export const MainDial = () => {
  const { mainService } = useContext(GlobalStateContext)

  const boxRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  const addMic = useCallback(() => {
    mainService.send({ type: "ADD_MIC" })
  }, [mainService])

  const addPlayer = useCallback(() => {
    mainService.send({ type: "ADD_PLAYER" })
  }, [mainService])

  const onMainClick: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault()
    setOpen(o => !o)
    menuRef.current?.focus()
  }

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>) => {
    // only close if we don't refocus to a child
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpen(false)
    }
  }
  const getButClass = (o: boolean) => o
    ? "btn btn-circle duration-200 opacity-100 scale-100"
    : "btn btn-circle duration-200 opacity-0 scale-0"
  const butStyle = { transition: 'opacity 0.2s ease-in-out, transform 0.2s ease-in-out' }
  return (
    <div className="relative w-12 h-12" ref={boxRef}>
      <div
        tabIndex={0}
        className={
          "bg-base-300 absolute rounded-full transition-all duration-200 ease-in-out flex flex-col-reverse  "
          + (
            open
              ? " h-80 w-12 z-10 bottom-0 justify-between drop-shadow-xl shadow-primary "
              : " h-12 w-12  bottom-0"
          )
        }
        ref={menuRef}
        onBlur={handleBlurEvent}
      >
        <button className={getButClass(open)} style={butStyle} onClick={() => setOpen(false)}>
          <svg
            className="swap-on fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 512 512">
            <polygon
              points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
          </svg>
        </button>
        <button className={getButClass(open)} style={butStyle} ><Fullscreen /></button>
        <button className={getButClass(open)} style={butStyle} ><Settings /></button>
        <button className={getButClass(open)} style={butStyle} onClick={addMic}>
          <Mic add={true} size='26px' transform='translate(12.5 12.5) scale(0.6)' />
        </button>
        <button className={getButClass(open)} style={butStyle} onClick={addPlayer}>
          <PlayArrow add={true} size='32px' transform='translate(12.5 12.5) scale(0.5)' />
        </button>
        <label className={"swap swap-rotate " + getButClass(open)} style={butStyle}>
          {/* this hidden checkbox controls the state */}
          <input type="checkbox" className="theme-controller" value="dim" />

          {/* sun icon */}
          <svg
            className="swap-off h-8 w-8 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24">
            <path
              d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" />
          </svg>

          {/* moon icon */}
          <svg
            className="swap-on h-8 w-8 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24">
            <path
              d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" />
          </svg>
        </label>

      </div>

      <button className="absolute btn btn-circle left-0 top-0 " onClick={onMainClick} >
        <svg
          className="swap-off fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 512 512">
          <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
        </svg>
      </button>

    </div>

  )
}

export type PlaylistAddDialProps = {
  onSearchModal: (o: boolean) => void
  service: PlayerService
}
export const PlaylistAddDial: FC<PlaylistAddDialProps> = ({
  onSearchModal,
  service
}) => {
  const plService = useSelector(service, s => s.context.playlistRef)
  const boxRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  const onAddFiles = useCallback((files: FileList | Array<File>) => {
    if (plService) {
      plService.send({ type: 'PLAYLIST_ADD_FILES', files })
    }
  }, [plService])

  const onMainClick: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault()
    setOpen(o => !o)
    menuRef.current?.focus()
  }

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>) => {
    // only close if we don't refocus to a child
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpen(false)
    }
  }

  return (
    <div className="relative w-8 h-8" ref={boxRef}>
      <div
        tabIndex={0}
        className={"bg-base-300 absolute drop-shadow-lg rounded-full transition-all duration-200 ease-in-out h-8 flex flex-row justify-between "}
        ref={menuRef}
        onBlur={handleBlurEvent}
        style={
          (open
            ? { width: '14rem', left: 0, opacity: '1', zIndex: 10 }
            : { width: 2, left: 0, opacity: '0' }
          )
        }
      >
        <button disabled={!open} className="btn btn-sm btn-circle " onClick={() => setOpen(false)}><ChevronLeft /></button>
        <button disabled={!open} className="btn btn-sm btn-circle " onClick={() => onSearchModal(true)}><Search /></button>
        <FileAddButton open={open} onAddFiles={onAddFiles} />
        <button disabled={!open} className="btn btn-sm btn-circle "><Record /></button>
        <button disabled={!open} className="btn btn-sm btn-circle "><WebLink /></button>

      </div>

      <button className="absolute btn btn-sm btn-circle left-0 top-0 " onClick={onMainClick} ><List /></button>
    </div>
  )
}

export const PlaylistItemDial = () => {

  const boxRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  const preventBubble: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const onMainClick: React.MouseEventHandler<HTMLElement> = (e) => {
    preventBubble(e)
    setOpen(o => !o)
    menuRef.current?.focus()
  }

  const onCloseClick: React.MouseEventHandler<HTMLElement> = (e) => {
    preventBubble(e)
    setOpen(false)
  }

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>) => {
    // only close if we don't refocus to a child
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpen(false)
    }
  }

  return (
    <div className="relative w-8 h-8" ref={boxRef} onClick={preventBubble}>
      <div
        tabIndex={0}
        className={"bg-base-300 absolute drop-shadow-lg rounded-full transition-all duration-200 ease-in-out h-8 flex flex-row justify-between "}
        ref={menuRef}
        onBlur={handleBlurEvent}
        style={
          (open
            ? { width: '12rem', left: 0, opacity: '1', zIndex: 10 }
            : { width: 2, left: 0, opacity: '0' }
          )
        }
      >
        <button className="btn btn-circle btn-sm " onClick={onCloseClick} disabled={!open}><ChevronLeft /></button>
        <button className="btn btn-circle btn-sm " disabled={!open}><Download /></button>
        <button className="btn btn-circle btn-sm " disabled={!open}><Edit /></button>
        <button className="btn btn-circle btn-sm " disabled={!open}><Delete /></button>
      </div>

      <button className="absolute btn btn-sm btn-circle left-0 top-0 " onClick={onMainClick} disabled={open}><ChevronRight /></button>

    </div>

  )
}

export const PlayDial = () => {
  const boxRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [w, setW] = useState(2)
  const [l, setL] = useState(2)

  const onMainClick: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault()
    setOpen(o => !o)
    menuRef.current?.focus()
  }

  useEffect(() => {
    const parent = boxRef.current?.parentElement || document.createElement('div')
    const onResize = () => {
      if (parent) {
        const l = boxRef.current?.offsetLeft || 40
        setW(parent.offsetWidth)
        setL(-l)
      }
    }
    //window.addEventListener('resize', onResize)
    parent.addEventListener('transitionend', onResize)
    onResize()
    return () => {
      parent.removeEventListener('transitionend', onResize)
    }
  }, [])

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>) => {
    // only close if we don't refocus to a child
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpen(false)
    }
  }

  return (
    <div className="relative w-8 h-8" ref={boxRef}>
      <div
        tabIndex={0}
        className={"bg-base-300 absolute rounded-full transition-all duration-200 ease-in-out h-8 flex flex-row justify-between"}
        ref={menuRef}
        onBlur={handleBlurEvent}
        style={
          (open
            ? { width: w, left: l, opacity: '1', zIndex: 10 }
            : { width: 2, left: 0, opacity: '0' }
          )
        }
      >
        <button className="btn btn-circle btn-sm btn-primary" />
        <button className="btn btn-circle btn-sm btn-primary" />
        <button className="btn btn-circle btn-sm btn-primary" />
        <button className="btn btn-circle btn-sm btn-primary" />
        <button className="btn btn-circle btn-sm btn-primary" />
      </div>

      <button className="absolute w-8 h-8 rounded-full bg-primary left-0 top-0 " onClick={onMainClick} />

    </div>

  )
}


export const TimeDial: FC<{ service: PlayerService }> = ({
  service
}) => {
  const boxRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const duration = useSelector(service, (s) => s.context.duration || 0.001) // no division by zero
  const value = useSelector(
    service,
    (s) => s.context.mediaNode?.mediaElement?.currentTime || 0
  )

  const [open, setOpen] = useState(false)
  const [w, setW] = useState(2)
  const [l, setL] = useState(2)
  const onMainClick: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault()
    setOpen(o => !o)
    menuRef.current?.focus()
  }
  useEffect(() => {
    const parent = boxRef.current?.parentElement || document.createElement('div')
    const onResize = () => {
      if (parent) {
        const l = (boxRef.current?.offsetLeft || 40) - parent.offsetLeft
        setW(parent.offsetWidth)
        setL(-l)
      }
    }

    window.addEventListener('resize', onResize)
    parent.addEventListener('transitionend', onResize)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
      parent.removeEventListener('transitionend', onResize)
    }
  }, [])

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>) => {
    // only close if we don't refocus to a child
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpen(false)
    }
  }
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const time = parseFloat(e.currentTarget.value)
    return isFinite(time)
      ? service.send({ type: 'SET_TIME', time })
      : null
  }

  return (
    <div
      className="relative w-16 h-8"
      ref={boxRef}
    >
      <div
        tabIndex={0}
        onBlur={handleBlurEvent}
        ref={menuRef}
        className="bg-base-300 rounded-t absolute transition-all duration-200 ease-in-out h-8 flex flex-row justify-between gap-2 px-2"
        style={
          (open
            ? { width: w, left: l, opacity: '1', zIndex: 10 }
            : { width: 2, left: 0, opacity: '0' }
          )
        }
      >
        <div className="h-8 w-16 text-xl" >{formatTime(value)}</div>
        <input
          type="range"
          disabled={!open || !duration}
          className="range range-lg touch-none [--range-shdw:rgba(0,0,0,0)]"
          value={value}
          min={0}
          max={duration}
          onChange={onChange}
        />
      </div>
      <button
        onClick={onMainClick} className="absolute left-0 top-0 h-8 w-16 text-xl">{formatTime(value)}</button>
    </div>
  )
}

export type PlayMenuProps = {
  service: PlayerService
}

export const PlayMenu: FC<PlayMenuProps> = ({
  service
}) => {
  const [open, setOpen] = useState(false)
  const onMainClick = () => setOpen(o => !o)
  const transition = 'opacity 0.15s ease-in-out, transform 0.15s ease-in-out'

  const plService = useSelector(service, (s) => s.context.playlistRef)
  const isLooping = useSelector(service, (s) => s.context.loop)
  const playLabel = useSelector(service, (s) =>
    // @ts-ignore
    s.matches('ready.playing')
      ? 'pause'
      // @ts-ignore
      : s.matches('ready.idle')
        ? 'play'
        : '...'
  )

  const preventBubble: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const stop: React.MouseEventHandler<HTMLElement> = (e) => { preventBubble(e); service.send({ type: 'STOP' }) }
  const loop: React.MouseEventHandler<HTMLElement> = (e) => { preventBubble(e); service.send({ type: 'TOGGLE_LOOP' }) }
  const next: React.MouseEventHandler<HTMLElement> = (e) => { preventBubble(e); plService.send({ type: 'LOAD_NEXT' }) }
  const prev: React.MouseEventHandler<HTMLElement> = (e) => { preventBubble(e); plService.send({ type: 'LOAD_PREV' }) }
  const play: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    return playLabel === 'pause'
      ? service.send({ type: 'PAUSE' })
      : service.send({ type: 'PLAY' })
  }

  const onBlur: React.FocusEventHandler<HTMLElement> = (e) => {
    console.log('blur', e)
    setOpen(false)
  }

  return (
    <div className={"relative w-12 h-12 " + (open ? ' z-10' : ' z-0')} onBlur={onBlur} >

      <div
        className="bg-base-300 rounded-full absolute left-0 top-0 w-12 h-12"
        style={
          (open
            ? { transition, opacity: 0.9, transform: 'scale(3)' }
            : { transition, opacity: 0, transform: 'scale(0)' }
          )
        } />
      <button
        className="btn btn-circle btn-primary absolute left-0 top-0"
        disabled={!open}
        onPointerDown={next}
        style={
          (open
            ? { transition, opacity: 1, transform: 'scale(1) translate(3.5rem, 0rem) ' }
            : { transition, opacity: 0.5, transform: 'scale(0.4) translate(3.5rem, 0rem)' }
          )
        }><Next size="36" /></button>

      <button
        className="btn btn-circle btn-primary absolute left-0 top-0"
        disabled={!open}
        onPointerDown={stop}
        style={
          (open
            ? { transition, opacity: 1, transform: 'scale(1) translate(-1.838626960862054rem,-2.978162335869414rem) ' }
            : { transition, opacity: 0.5, transform: 'scale(0.4) translate(-1.838626960862054rem,-2.978162335869414rem) ' }
          )
        }><Stop size="36" /></button>

      <button
        className="btn btn-circle btn-primary absolute left-0 top-0"
        onPointerDown={play}
        disabled={playLabel === '...' || !open}
        style={
          (open
            ? { transition, opacity: 1, transform: 'scale(1) translate(1.838626960862054rem,-2.978162335869414rem) ' }
            : { transition, opacity: 0.5, transform: 'scale(0.4) translate(1.838626960862054rem,-2.978162335869414rem) ' }
          )
        }>{
          playLabel === 'play'
            ? < Play size="36" />
            : playLabel === 'pause'
              ? <Pause size="36" />
              : <Pending size="36" />
        }
      </button>

      <button
        className="btn btn-circle btn-primary absolute left-0 top-0"
        disabled={!open}
        onPointerDown={prev}
        style={
          (open
            ? { transition, opacity: 1, transform: 'scale(1) translate(-3.5rem, 0) ' }
            : { transition, opacity: 0.5, transform: 'scale(0.4) translate(-3.5rem, 0) ' }
          )
        }><Prev size="36" /></button>
      <button
        className={"btn btn-circle btn-primary absolute left-0 top-0 " + (isLooping ? "text-accent bg-base-300" : "")}
        disabled={!open}
        onPointerDown={loop}
        style={
          (open
            ? { transition, opacity: 1, transform: 'scale(1) translate(0rem,3.5rem) ' }
            : { transition, opacity: 0.5, transform: 'scale(0.4) translate(0rem,3.5rem) ' }
          )
        }><Loop size="36" /></button>
      <button
        className={"btn btn-circle btn-primary absolute w-12 h-12 left-0 top-0 " + (open ? "opacity-30" : "")}
        onClick={onMainClick}>
        {open
          ? <Play size="46px" />
          : <PlayArrow size="36px" />
        }
        {isLooping && !open
          ? <Loop className="absolute bottom-0 right-0 scale-50" />
          : null
        }
      </button>

    </div>


  )
}

